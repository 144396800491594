"use client";

import { useEffect } from "react";
import ErrorPage from "rivals/components/pages/error/ErrorPage";
import logger from "rivals/shared/utils/logger";

const PAGE_NAME = "root";

// TODO: https://griosf.atlassian.net/browse/RVLS-8613
export default function Error({
  error
}: {
  error: Error & { digest?: string };
}): React.JSX.Element {
  useEffect(() => {
    logger({ message: error, page: PAGE_NAME, severity: "error" });
  }, [error]);

  logger({ message: "hit", page: PAGE_NAME, severity: "error" });

  return (
    <div className="you-shall-not-pass-root">
      <ErrorPage />
    </div>
  );
}
